import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';

import { useCompanies } from '@/hooks/v1';
import { SelectWithCreation } from '@/components/ui/Inputs';
import { HierarchiesField, hierarchyHandler } from '@/utils/HierarchyHandler';
import { Label } from './styles';

const { useFormInstance, useWatch } = Form;

export function Companies() {
	const form = useFormInstance();
	const field_name = HierarchiesField.COMPANY;
	const company_id = useWatch(HierarchiesField.COMPANY, form);
	const organization_id = useWatch(HierarchiesField.ORGANIZATION, form);

	const {
		data: companies,
		isFetching: isGettingCompanies,
		isError: errorGettingCompanies
	} = useCompanies(organization_id);

	function handleSelectCompany(id: string): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset && id) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
			form.setFieldValue(field_name, id);
		}
	}

	function handleClearCompany(): void {
		const fieldsToReset = hierarchyHandler.getHierarchiesFieldsToClear(field_name);
		if (fieldsToReset) {
			form.resetFields([...fieldsToReset, 'evaluator_id']);
		}
	}

	return (
		<Form.Item name={field_name} label={<Label>{I18n.get('Industrial site')}</Label>}>
			<SelectWithCreation
				showSearch
				allowClear
				value={company_id}
				selectOptions={companies}
				loading={isGettingCompanies}
				onClear={handleClearCompany}
				onChange={handleSelectCompany}
				placeholder={I18n.get('Industrial site')}
				disabled={!organization_id || !companies || errorGettingCompanies}
			/>
		</Form.Item>
	);
}
