import React from 'react';
import { Row, Col } from 'antd';
import moment, { Moment } from 'moment';
import { Sectors } from './Sectors';
import { Cellules } from './Cellules';
import { FileName } from './FileName';
import { Companies } from './Companies';
import { CreatedAt } from './CreatedAt';
import { Workstations } from './Workstations';
import { Organizations } from './Organizations';
import { FilterCollapseForm } from '@/components/FilterCollapseForm';
import type { FileFilterParams, FormFields } from '../types';

interface FilterProps {
	queryParams: FileFilterParams;
}

export function Filter({ queryParams }: Readonly<FilterProps>) {
	const initialValues: FormFields = mapFilterInitialValues(queryParams) ?? {};

	const queryParamsArray = Object.keys(queryParams);
	const urlValues = Object.values(queryParams).filter((params) => !!params);
	const hasSearchParams = urlValues.length > 0;
	const cleared = queryParamsArray[0] === 'cleared';

	function mapFilterInitialValues({ created_at }: FileFilterParams): FormFields {
		return {
			...queryParams,
			created_at: created_at ? formatDateToInput(created_at) : undefined
		};
	}

	function formatDateToInput(created_at: string[]): Moment[] {
		const [startDate, endDate] = created_at;
		return [moment(startDate), moment(endDate)];
	}

	return (
		<FilterCollapseForm
			showSearchButton
			initialValues={initialValues}
			isCollapseOpen={hasSearchParams}
			disableClearButton={!hasSearchParams || cleared}
		>
			<Col xs={24}>
				<Row gutter={[{ lg: 20, xxl: 55 }, 0]}>
					<Col lg={24} xxl={12}>
						<FileName />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<CreatedAt />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<Organizations />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<Companies />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<Sectors />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<Cellules />
					</Col>
					<Col lg={12} xl={8} xxl={6}>
						<Workstations />
					</Col>
				</Row>
			</Col>
		</FilterCollapseForm>
	);
}
