import React from 'react';

export function BodyChecklist(props: Readonly<React.SVGProps<SVGSVGElement>>) {
	return (
		<svg {...props} width="84" height="74" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_3872_12042)">
				<path
					d="M32.6506 38.6932C31.3157 37.3104 29.7559 36.1818 28.0427 35.3591C30.4586 33.3333 32 30.2421 32 26.7756C32 20.6595 27.072 15.6418 21.1627 15.7357C15.3441 15.8295 10.6561 20.7368 10.6561 26.7756C10.6561 30.2421 12.2028 33.3333 14.6134 35.3591C12.8999 36.1812 11.34 37.3099 10.0055 38.6932C7.09349 41.7126 5.44017 45.698 5.33351 49.9484C5.33208 50.0073 5.34207 50.0659 5.36287 50.1208C5.38368 50.1756 5.41488 50.2256 5.45464 50.2678C5.4944 50.31 5.54192 50.3435 5.59439 50.3664C5.64686 50.3893 5.70323 50.4011 5.76017 50.401H8.74681C8.97614 50.401 9.16814 50.2134 9.17348 49.976C9.27481 46.7744 10.5281 43.7771 12.7308 41.5029C13.8579 40.3301 15.1986 39.4003 16.6752 38.7674C18.1519 38.1344 19.7351 37.8109 21.3334 37.8155C24.5813 37.8155 27.6373 39.1238 29.936 41.5029C32.1333 43.7771 33.3866 46.7744 33.4933 49.976C33.4986 50.2134 33.6906 50.401 33.9199 50.401H36.9066C36.9635 50.4011 37.0199 50.3893 37.0724 50.3664C37.1248 50.3435 37.1723 50.31 37.2121 50.2678C37.2519 50.2256 37.2831 50.1756 37.3039 50.1208C37.3247 50.0659 37.3347 50.0073 37.3332 49.9484C37.2266 45.698 35.5733 41.7126 32.6506 38.6932ZM21.3334 33.8411C19.5094 33.8411 17.7921 33.107 16.5067 31.7712C15.8617 31.1088 15.3522 30.3187 15.0086 29.4481C14.6649 28.5775 14.4943 27.6442 14.5068 26.7038C14.5228 24.8933 15.2214 23.1435 16.4427 21.8463C17.7227 20.4884 19.4347 19.7321 21.264 19.71C23.072 19.6935 24.8267 20.4221 26.1173 21.7303C27.44 23.0717 28.1653 24.8657 28.1653 26.7756C28.1653 28.6634 27.456 30.4353 26.1653 31.7712C25.5321 32.4297 24.779 32.9517 23.9496 33.307C23.1202 33.6623 22.231 33.8439 21.3334 33.8411Z"
					fill="currentColor"
				/>
				<path
					d="M56.9419 13.7229C57.4631 14.2431 57.464 15.0873 56.9438 15.6085L53.8212 18.7374C53.5611 18.9981 53.2047 19.1395 52.8366 19.1283C52.4685 19.117 52.1215 18.954 51.8778 18.6778L50.2945 16.884C49.8072 16.3319 49.8598 15.4893 50.4119 15.002C50.964 14.5147 51.8065 14.5673 52.2938 15.1194L52.9371 15.8482L55.0563 13.7248C55.5765 13.2036 56.4207 13.2027 56.9419 13.7229ZM41.3334 17.0133C41.3334 16.2769 41.9303 15.68 42.6667 15.68H45.804C46.5403 15.68 47.1373 16.2769 47.1373 17.0133C47.1373 17.7497 46.5403 18.3467 45.804 18.3467H44V32.1067H45.804C46.5403 32.1067 47.1373 32.7036 47.1373 33.44C47.1373 34.1764 46.5403 34.7733 45.804 34.7733H44V47.7511H45.804C46.5403 47.7511 47.1373 48.3481 47.1373 49.0844C47.1373 49.8208 46.5403 50.4178 45.804 50.4178H42.6667C41.9303 50.4178 41.3334 49.8208 41.3334 49.0844V17.0133ZM56.9419 30.1496C57.4631 30.6697 57.464 31.514 56.9438 32.0352L53.8212 35.1641C53.5611 35.4247 53.2047 35.5662 52.8366 35.5549C52.4685 35.5436 52.1215 35.3806 51.8778 35.1045L50.2945 33.3106C49.8072 32.7585 49.8598 31.9159 50.4119 31.4287C50.964 30.9414 51.8065 30.9939 52.2938 31.546L52.9371 32.2749L55.0563 30.1515C55.5765 29.6302 56.4207 29.6294 56.9419 30.1496ZM56.9419 45.794C57.4631 46.3142 57.464 47.1584 56.9438 47.6796L53.8212 50.8085C53.5611 51.0692 53.2047 51.2107 52.8366 51.1994C52.4685 51.1881 52.1215 51.0251 51.8778 50.749L50.2945 48.9551C49.8072 48.403 49.8598 47.5604 50.4119 47.0731C50.964 46.5858 51.8065 46.6384 52.2938 47.1905L52.9371 47.9193L55.0563 45.7959C55.5765 45.2747 56.4207 45.2738 56.9419 45.794Z"
					fill="currentColor"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3872_12042">
					<rect width="74" height="74" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
