import React from 'react';
import { Form } from 'antd';
import { I18n } from '@aws-amplify/core';
import { CustomInput, Label } from './styles';

export function FileName() {
	return (
		<Form.Item name={['original_name']} label={<Label>{I18n.get('File name')}</Label>}>
			<CustomInput placeholder={I18n.get('File name')} />
		</Form.Item>
	);
}
