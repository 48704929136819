import { Button } from 'antd';
import styled from 'styled-components';

export const CustomButton = styled(Button)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	font-size: 1rem;
	padding: 1.1rem;
	border-radius: 6px;

	span {
		display: flex;
		align-items: center;
	}
`;
